import { createModel } from '@rematch/core';
import { SearchResult, IResultState, ISearchParams } from 'models/result.models';
import { resultService } from 'services/result.service';
import { IRootModel } from 'store';

export const result = createModel<IRootModel>()({
  state: {
    // TODO
    data: null,
    loading: false,
  } as IResultState,
  reducers: {
    // TODO
    setResult: (state, payload: SearchResult) => ({ ...state, data: payload }),
    setResultLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getResult(params: ISearchParams) {
      dispatch.result.setResultLoading(true);

      await resultService
        .getResult(params)
        .then((response) => {
          // TODO
          dispatch.result.setResult(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          dispatch.result.setResultLoading(false);
        });
    },
  }),
});
