import { init, RematchRootState, RematchDispatch, Models } from '@rematch/core';
import { result } from 'slices/result.slice';

export interface IRootModel extends Models<IRootModel> {
  result: typeof result,
}

const models = {
  result,
} as IRootModel;

const store = init<IRootModel>({
  models,
});

export default store;
export type AppState = RematchRootState<IRootModel>;
export type AppDispatch = RematchDispatch<IRootModel>;
