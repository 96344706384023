import React, { FC, useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';

export const App: FC = () => {
  const { resultState } = useAppSelector((state) => ({ resultState: state.result }));
  const { result: resultDispatch } = useAppDispatch();
  const [firstTextareaValue, setFirstTextareaValue] = useState<string>('');

  // eslint-disable-next-line
  const { data, loading } = resultState;
  const { getResult } = resultDispatch;

  const onSearchClick = () => {
    getResult({ request: firstTextareaValue });
  };

  return (
    <div className="app">
      <div className="block">
        <div className="title">Информация о товаре</div>

        <textarea
          className="textarea"
          rows={10}
          value={firstTextareaValue}
          onChange={(e) => setFirstTextareaValue(e.target.value)}
        />
      </div>

      <div className="footer">
        <button className="footer_btn" onClick={onSearchClick} disabled={loading}>
          {loading && <span className="loader" />}

          <span>Найти</span>
        </button>
      </div>

      <div className="block">
        <div className="title">Найденные совпадения</div>
        <span>{data?.result}</span>
      </div>
    </div>
  );
};
